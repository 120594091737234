import {Component, inject, Input} from '@angular/core';
import {ReportService} from "../../service/report.service";
import {Order} from "../../model/order";
import {saveAs} from "file-saver-es";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-report',
  standalone: true,
  imports: [
    NgIf
  ],
  template: `
    <div class="mb-3" style="margin-top: 25px;">
      <h3>Download Reports</h3>
      <div>
        <button [disabled]="!hasDevices()" class="mb-3 btn btn-outline-primary" (click)="generateImeiStatusReport()">IMEI Status</button>
      </div>
      <div>
        <button *ngIf="order.orderType !== 'DEACTIVATION'" [disabled]="!hasActivatedDevices()" class="mb-3 btn btn-outline-primary" (click)="generateBlxReport()">BLX</button>
      </div>
      <div *ngIf="order.orderType === 'DEACTIVATION' || order.orderType === 'SWAP'">
        <button class="mb-3 btn btn-outline-primary" (click)="generateDeactivationReport()">Deactivation Report</button>
      </div>
      <div *ngIf="deactivationReportError" class="alert alert-danger">Could not generate deactivation report.</div>
      <div>
        <button *ngIf="order.orderType !== 'DEACTIVATION'" [disabled]="!hasDevices()" class="mb-3 btn btn-outline-primary" (click)="downloadInReachConfigs()">Download inReach Configs</button>
      </div>
      <div>
        <button *ngIf="order.orderType === 'NEW' || order.orderType === 'EXISTING'" [disabled]="!hasDevices()" class="mb-3 btn btn-outline-primary" (click)="recreateCsv()">Recreate Upload CSV</button>
      </div>
    </div>
  `,
  styleUrl: './report.component.css'
})
export class ReportComponent {
  reportService = inject(ReportService);

  @Input() order!: Order;

  deactivationReportError = false;

  generateImeiStatusReport() {
    const imeiInfoIds = <number[]>this.order.imeiInfo?.map(
      imeiInfo => imeiInfo.imeiInfoId);

    if (imeiInfoIds && imeiInfoIds.length > 0) {
      this.reportService.generateImeiStatusReport(imeiInfoIds).subscribe({
        next: report => {
          saveAs(this.getData(report), "imei_status_report_" + Date.now() + ".csv");
        },
        error: err => {
          console.log(err);
        }
      });
    }
  }

  generateBlxReport() {
    const imeiInfoIds = <number[]>this.order
      .imeiInfo?.filter(imeiInfo => imeiInfo.activationStatus === 'ACTIVATED')
      .map(imeiInfo => imeiInfo.imeiInfoId);

    if (imeiInfoIds && imeiInfoIds.length > 0) {
      this.reportService.generateBlxReport(imeiInfoIds).subscribe({
        next: report => {
          saveAs(this.getData(report), "blx_report_" + Date.now() + ".csv");
        },
        error: err => {
          console.log(err);
        }
      });
    }
  }

  recreateCsv(imeiInfoId? : number) {
    const imeiInfoIds = imeiInfoId ? [imeiInfoId] : <number[]>this.order.imeiInfo?.map(imeiInfo => imeiInfo.imeiInfoId);

    if (imeiInfoIds && imeiInfoIds.length > 0) {
      this.reportService.recreateCsv(imeiInfoIds).subscribe({
        next: report => {
          saveAs(this.getData(report), "order_"+ this.order.orderId + "_" + Date.now() + ".csv");
        },
        error: err => {
          console.log(err);
        }
      });
    }
  }

  generateDeactivationReport() {
    this.deactivationReportError = false;
    this.reportService.generateDeactivationReport(this.order.orderId).subscribe({
      next: report => {
        saveAs(this.getData(report), "deactivation_report_" + Date.now() + ".csv");
      },
      error: err => {
        console.log(err);
        this.deactivationReportError = true;
      }
    });
  }

  downloadInReachConfigs() {
    const imeiInfoIds = <number[]>this.order.imeiInfo?.map(
        imeiInfo => imeiInfo.imeiInfoId);

    if (imeiInfoIds && imeiInfoIds.length > 0) {
      this.reportService.downloadInReachConfigs(this.order.orderId).subscribe({
        next: configs => {
          const blob = new Blob([configs], {
            type: 'application/zip'
          });

          saveAs(blob, "inReach_Configs_" + Date.now() + ".zip");
        },
        error: err => {
          console.log(err);
        }
      });
    }
  }

  private getData(report: string) {
    return new Blob([report], {
      type: "text/csv;charset=utf-8"
    });
  }

  hasActivatedDevices() {
    return this.order?.imeiInfo?.some(imeiInfo => imeiInfo.activationStatus === 'ACTIVATED');
  }

  hasDevices() {
    return this.order?.imeiInfo && this.order.imeiInfo.length > 0;
  }
}
