import {environment} from "../../environments/environment";
import {HttpMethod} from "@auth0/auth0-angular";

export const authConfig = {
  domain: environment.auth0.domain,
  clientId: environment.auth0.clientId,
  authorizationParams: {
    redirect_uri: environment.auth0.authorizationParams.redirect_uri,
    audience: environment.auth0.authorizationParams.audience,
    scope: 'email authenticate validate:imei read:order write:order write:customer activate:device read:team update:device read:documentation'
  },
  httpInterceptor: {
    allowedList: [
      {
        uri: environment.apiUrlPrefix + 'device/validate/*',
        httpMethod: HttpMethod.Get,
        tokenOptions: {
          authorizationParams: {
            audience: environment.auth0.authorizationParams.audience,
            scope: 'validate:imei'
          },
        }
      },
      {
        uri: environment.apiUrlPrefix + 'device/validate',
        httpMethod: HttpMethod.Get,
        tokenOptions: {
          authorizationParams: {
            audience: environment.auth0.authorizationParams.audience,
            scope: 'validate:imei'
          },
        }
      },
      {
        uri: environment.apiUrlPrefix + 'orders',
        httpMethod: HttpMethod.Get,
        tokenOptions: {
          authorizationParams: {
            audience: environment.auth0.authorizationParams.audience,
            scope: 'read:order'
          },
        }
      },
      {
        uri: environment.apiUrlPrefix + 'order/*',
        httpMethod: HttpMethod.Get,
        tokenOptions: {
          authorizationParams: {
            audience: environment.auth0.authorizationParams.audience,
            scope: 'read:order'
          },
        }
      },
      {
        uri: environment.apiUrlPrefix + 'order/*',
        httpMethod: HttpMethod.Delete,
        tokenOptions: {
          authorizationParams: {
            audience: environment.auth0.authorizationParams.audience,
            scope: 'write:order'
          },
        }
      },
      {
        uri: environment.apiUrlPrefix + 'order',
        httpMethod: HttpMethod.Post,
        tokenOptions: {
          authorizationParams: {
            audience: environment.auth0.authorizationParams.audience,
            scope: 'write:order'
          },
        }
      },
      {
        uri: environment.apiUrlPrefix + 'bulkUpload',
        httpMethod: HttpMethod.Post,
        tokenOptions: {
          authorizationParams: {
            audience: environment.auth0.authorizationParams.audience,
            scope: 'write:order'
          },
        }
      },
      {
        uri: environment.apiUrlPrefix + 'order',
        httpMethod: HttpMethod.Patch,
        tokenOptions: {
          authorizationParams: {
            audience: environment.auth0.authorizationParams.audience,
            scope: 'write:order'
          },
        }
      },
      {
        uri: environment.apiUrlPrefix + 'internalOrder',
        httpMethod: HttpMethod.Post,
        tokenOptions: {
          authorizationParams: {
            audience: environment.auth0.authorizationParams.audience,
            scope: 'write:order'
          },
        }
      },
      {
        uri: environment.apiUrlPrefix + 'device/activate',
        httpMethod: HttpMethod.Patch,
        tokenOptions: {
          authorizationParams: {
            audience: environment.auth0.authorizationParams.audience,
            scope: 'activate:device'
          },
        }
      },
      {
        uri: environment.apiUrlPrefix + 'device/deactivate',
        httpMethod: HttpMethod.Patch,
        tokenOptions: {
          authorizationParams: {
            audience: environment.auth0.authorizationParams.audience,
            scope: 'update:device'
          },
        }
      },
      {
        uri: environment.apiUrlPrefix + 'customer/*',
        httpMethod: HttpMethod.Post,
        tokenOptions: {
          authorizationParams: {
            audience: environment.auth0.authorizationParams.audience,
            scope: 'write:customer'
          },
        }
      },
      {
        uri: environment.apiUrlPrefix + 'hub/team/*',
        httpMethod: HttpMethod.Get,
        tokenOptions: {
          authorizationParams: {
            audience: environment.auth0.authorizationParams.audience,
            scope: 'read:team'
          },
        }
      },
      {
        uri: environment.apiUrlPrefix + 'devices',
        httpMethod: HttpMethod.Patch,
        tokenOptions: {
          authorizationParams: {
            audience: environment.auth0.authorizationParams.audience,
            scope: 'update:device'
          },
        }
      },
      {
        uri: environment.apiUrlPrefix + 'devices',
        httpMethod: HttpMethod.Delete,
        tokenOptions: {
          authorizationParams: {
            audience: environment.auth0.authorizationParams.audience,
            scope: 'update:device'
          },
        }
      },
      {
        uri: environment.apiUrlPrefix + 'report/imei',
        httpMethod: HttpMethod.Post,
        tokenOptions: {
          authorizationParams: {
            audience: environment.auth0.authorizationParams.audience,
            scope: 'read:order'
          },
        }
      },
      {
        uri: environment.apiUrlPrefix + 'report/blx',
        httpMethod: HttpMethod.Post,
        tokenOptions: {
          authorizationParams: {
            audience: environment.auth0.authorizationParams.audience,
            scope: 'read:order'
          },
        }
      },
      {
        uri: environment.apiUrlPrefix + 'report/recreate/csv',
        httpMethod: HttpMethod.Post,
        tokenOptions: {
          authorizationParams: {
            audience: environment.auth0.authorizationParams.audience,
            scope: 'read:order'
          },
        }
      },
      {
        uri: environment.apiUrlPrefix + 'report/deactivation/*',
        httpMethod: HttpMethod.Get,
        tokenOptions: {
          authorizationParams: {
            audience: environment.auth0.authorizationParams.audience,
            scope: 'read:order'
          },
        }
      },
    ],
  },
}
