import {Component, inject} from '@angular/core';
import {Order} from "../../model/order";
import {OrderSummaryComponent} from "../order-summary/order-summary.component";
import {HttpClient} from "@angular/common/http";
import {NgForOf} from "@angular/common";
import {environment} from "../../../environments/environment";
import {FormsModule} from "@angular/forms";
import {CdkDragDrop, CdkDropList, transferArrayItem} from "@angular/cdk/drag-drop";
import {OrderService} from "../../service/order.service";
import {OrderStatus} from "../../utils/OrderStatusConstants";
import {MatCheckbox} from "@angular/material/checkbox";

@Component({
  selector: 'app-orders',
  standalone: true,
  imports: [
    OrderSummaryComponent,
    NgForOf,
    FormsModule,
    CdkDropList,
    MatCheckbox
  ],
  template: `
    <div style="width: 95%; min-height: 100%; margin-left: auto; margin-right: auto;" class="row g-3">
      <div style="display: flex; justify-content: space-between;">
        <input class="form-control" style="width: 35%" type="search" [(ngModel)]="filterText" placeholder="Filter by company name, order number, or IMEI.">
        <mat-checkbox [disabled]="isLoadingOrders" (change)="toggleFilter()">Show all orders</mat-checkbox>
      </div>
      <div id="READY" class="border-start border-primary-subtle col" cdkDropList #readyList="cdkDropList"
           [cdkDropListData]="readyOrdersList"
           [cdkDropListConnectedTo]="[inProcessList, blxIdentifierUploadList, exceptionsList, billingActionsList, resolvedList, closedList]"
           (cdkDropListDropped)="drop($event)">
        <h5 style="text-align: center">Ready</h5>
        <section>
          <app-order *ngFor="let order of filterOrders(readyOrdersList)" [order]="order" [groupOrders]="getOrdersMethod()"></app-order>
        </section>
      </div>
      <div id="IN_PROCESS" class="border-start border-primary-subtle col" cdkDropList #inProcessList="cdkDropList"
           [cdkDropListData]="inProcessOrdersList"
           [cdkDropListConnectedTo]="[readyList, blxIdentifierUploadList, exceptionsList, billingActionsList, resolvedList, closedList]"
           (cdkDropListDropped)="drop($event)">
        <h5 style="text-align: center">In Process</h5>
        <section>
          <app-order *ngFor="let order of filterOrders(inProcessOrdersList)" [order]="order" [groupOrders]="getOrdersMethod()"></app-order>
        </section>
      </div>
      <div id="BLX_IDENTIFIER_UPLOAD" class="border-start border-primary-subtle col" cdkDropList #blxIdentifierUploadList="cdkDropList"
           [cdkDropListData]="blxIdentifierUploadOrdersList"
           [cdkDropListConnectedTo]="[readyList, inProcessList, exceptionsList, billingActionsList, resolvedList, closedList]"
           (cdkDropListDropped)="drop($event)">
        <h5 style="text-align: center">BLX Identifier Upload</h5>
        <section>
          <app-order *ngFor="let order of filterOrders(blxIdentifierUploadOrdersList)" [order]="order" [groupOrders]="getOrdersMethod()"></app-order>
        </section>
      </div>
      <div id="EXCEPTIONS" class="border-start border-primary-subtle col" cdkDropList #exceptionsList="cdkDropList"
           [cdkDropListData]="exceptionsOrdersList"
           [cdkDropListConnectedTo]="[readyList, inProcessList, blxIdentifierUploadList, billingActionsList, resolvedList, closedList]"
           (cdkDropListDropped)="drop($event)">
        <h5 style="text-align: center">Exceptions</h5>
        <section>
          <app-order *ngFor="let order of filterOrders(exceptionsOrdersList)" [order]="order" [groupOrders]="getOrdersMethod()"></app-order>
        </section>
      </div>
      <div id="BILLING_ACTIONS" class="border-start border-primary-subtle col" cdkDropList #billingActionsList="cdkDropList"
           [cdkDropListData]="billingActionsOrdersList"
           [cdkDropListConnectedTo]="[readyList, inProcessList, blxIdentifierUploadList, exceptionsList, resolvedList, closedList]"
           (cdkDropListDropped)="drop($event)">
        <h5 style="text-align: center">Billing Actions</h5>
        <section>
          <app-order *ngFor="let order of filterOrders(billingActionsOrdersList)" [order]="order" [groupOrders]="getOrdersMethod()"></app-order>
        </section>
      </div>
      <div id="RESOLVED" class="border-start border-primary-subtle col" cdkDropList #resolvedList="cdkDropList"
           [cdkDropListData]="resolvedOrdersList"
           [cdkDropListConnectedTo]="[readyList, inProcessList, blxIdentifierUploadList, exceptionsList, billingActionsList, closedList]"
           (cdkDropListDropped)="drop($event)">
        <h5 style="text-align: center">Resolved</h5>
        <section>
          <app-order *ngFor="let order of filterOrders(resolvedOrdersList)" [order]="order" [groupOrders]="getOrdersMethod()"></app-order>
        </section>
      </div>
      <div id="CLOSED" class="border-start border-end border-primary-subtle col" cdkDropList #closedList="cdkDropList"
           [cdkDropListData]="closedOrdersList"
           [cdkDropListConnectedTo]="[readyList, inProcessList, blxIdentifierUploadList, exceptionsList, billingActionsList, resolvedList]"
           (cdkDropListDropped)="drop($event)">
        <h5 style="text-align: center">Closed</h5>
        <section>
          <app-order *ngFor="let order of filterOrders(closedOrdersList)" [order]="order" [groupOrders]="getOrdersMethod()"></app-order>
        </section>
      </div>
    </div>
  `,
  styleUrl: './orders-page.component.css'
})
export class OrdersPageComponent {

  orderService: OrderService = inject(OrderService);

  constructor(private http : HttpClient) {
    this.getOrders();
  }

  readyOrdersList: Order[] = [];
  inProcessOrdersList: Order[] = [];
  blxIdentifierUploadOrdersList: Order[] = [];
  exceptionsOrdersList: Order[] = [];
  billingActionsOrdersList: Order[] = [];
  resolvedOrdersList: Order[] = [];
  closedOrdersList: Order[] = [];

  filterText: string = '';
  filterClosed: boolean = true;
  isLoadingOrders: boolean = false;

  toggleFilter() {
    this.filterClosed = !this.filterClosed;
    this.resetOrders();
  }

  getOrders() {
    this.isLoadingOrders = true;
    this.http.get<Order[]>(environment.apiUrlPrefix + 'orders', {params: {filterClosed: this.filterClosed}}).subscribe(data => {
      data.forEach(currentValue => {
        switch (currentValue.status) {
          case null:
          case undefined:
          case OrderStatus.READY:
            this.readyOrdersList.push(currentValue);
            break;
          case OrderStatus.IN_PROCESS:
            this.inProcessOrdersList.push(currentValue);
            break;
          case OrderStatus.BLX_IDENTIFIER_UPLOAD:
            this.blxIdentifierUploadOrdersList.push(currentValue);
            break;
          case OrderStatus.EXCEPTION:
            this.exceptionsOrdersList.push(currentValue);
            break;
          case OrderStatus.BILLING_ACTIONS:
            this.billingActionsOrdersList.push(currentValue);
            break;
          case OrderStatus.RESOLVED:
            this.resolvedOrdersList.push(currentValue);
            break;
          case OrderStatus.CLOSED:
            this.closedOrdersList.push(currentValue);
            break;
        }
      });
      this.isLoadingOrders = false;
    })
  }

  filterOrders(orders: Order[]) : Order[] {
    if (this.filterText.length > 0) {
      return orders.filter(order => {
        return order.companyName?.toUpperCase().includes(this.filterText.toUpperCase()) ||
          this.filterText === order.orderId.toString() ||
          order.imeiInfo?.some(imeiInfo => {
            return imeiInfo.imei === this.filterText;
          }) ||
          order.imeis?.some(imei => {
            return imei === this.filterText;
          });
      });
    }

    return orders;
  }

  getOrdersMethod(): any {
    return {
      callGetOrdersMethod: () => {
        this.resetOrders();
      }
    }
  }

  resetOrders(): void {
    this.readyOrdersList = [];
    this.inProcessOrdersList = [];
    this.blxIdentifierUploadOrdersList = [];
    this.exceptionsOrdersList = [];
    this.billingActionsOrdersList = [];
    this.resolvedOrdersList = [];
    this.closedOrdersList = [];
    this.getOrders();
  }

  drop(event: CdkDragDrop<Order[]>) {
    if (event.previousContainer !== event.container) {
      const order = event.previousContainer.data[event.previousIndex];
      const orderStatus = event.container.id;

      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );

      order.status = orderStatus;
      this.orderService.updateOrder(order.orderId, order).subscribe({
        error: (err) => {
          confirm("Error changing status");
          console.log(err);
          this.resetOrders();
        }
      });
    }
  }
}
