import {Address} from "./address";
import {EmergencyContact} from "./emergencyContact";
import {ImeiInfo} from "./imeiInfo";
export interface IOrder {
  orderId: number;
  creatorEmail: string;
  createdDateTime: string;
  firstName: string;
  lastName: string;
  companyName: string;
  teamName: string;
  orderType: string;
  planType: string;
  hardwareModel: string;
  hardwareColor: string;
  hardwareQuantity: number;
  updateMainPoc: boolean;
  updateEmergencyContacts: boolean;
  fulfilled: boolean;
  fulfilledBy: string;
  fulfilledDate: string;
  shippingAddress: Address;
  phoneNumber: string;
  emergencyContact: EmergencyContact;
  imeiInfo: ImeiInfo[];
  imeis: string[];
  status: string;
}

export class Order {
  public orderId: number;
  public creatorEmail: string | null;
  public createdDateTime: string | null;
  public firstName: string | null;
  public lastName: string | null;
  public companyName: string | null;
  public teamName: string | null;
  public orderType: string | null;
  public planType: string | null;
  public hardwareModel: string | null;
  public hardwareColor: string | null;
  public hardwareQuantity: number;
  public updateMainPoc: boolean;
  public updateEmergencyContacts: boolean;
  public fulfilled: boolean;
  public fulfilledBy: string | null;
  public fulfilledDate: string | null;
  public shippingAddress: Address | null;
  public phoneNumber: string | null;
  public emergencyContact: EmergencyContact | null;
  public imeiInfo: ImeiInfo[] | null;
  public imeis: string[] | null;
  public status: string | null;

  constructor(order?: IOrder) {
    this.orderId = order?.orderId ?? 0;
    this.creatorEmail = order?.creatorEmail ?? null;
    this.createdDateTime = order?.createdDateTime ?? null;
    this.firstName = order?.firstName ?? null;
    this.lastName = order?.lastName ?? null;
    this.companyName = order?.companyName ?? null;
    this.teamName = order?.teamName ?? null;
    this.orderType = order?.orderType ?? null;
    this.planType = order?.planType ?? null;
    this.hardwareModel = order?.hardwareModel ?? null;
    this.hardwareColor = order?.hardwareColor ?? null;
    this.hardwareQuantity = order?.hardwareQuantity ?? 0;
    this.updateMainPoc = order?.updateMainPoc ?? false;
    this.updateEmergencyContacts = order?.updateEmergencyContacts ?? false;
    this.fulfilled = order?.fulfilled ?? false;
    this.fulfilledBy = order?.fulfilledBy ?? null;
    this.fulfilledDate = order?.fulfilledDate ?? null;
    this.shippingAddress = order?.shippingAddress ?? null;
    this.phoneNumber = order?.phoneNumber ?? null;
    this.emergencyContact = order?.emergencyContact ?? null;
    this.imeiInfo = order?.imeiInfo ?? null;
    this.imeis = order?.imeis ?? null;
    this.status = order?.status ?? null;
  }
}

export const FIRST_NAME_MAX_CHARS = 255;
export const LAST_NAME_MAX_CHARS = 255;
export const PHONE_NUMBER_MAX_CHARS = 20;
export const COMPANY_NAME_MAX_CHARS = 255;
export const HARDWARE_MODEL_MAX_CHARS = 50;
export const PLAN_TYPE_MAX_CHARS = 50;
export const TEAM_NAME_MAX_CHARS = 255;
