import {Component, inject, Input} from '@angular/core';
import {Order} from "../../model/order";
import {MatDialog} from "@angular/material/dialog";
import {OrderDetailComponent} from "../order-detail/order-detail.component";
import {OrderService} from "../../service/order.service";
import {MatIcon} from "@angular/material/icon";
import {MatTooltip} from "@angular/material/tooltip";
import {DeleteOrderComponent} from "../../actions/delete-order/delete-order.component";
import {CdkDrag} from "@angular/cdk/drag-drop";
import {NgClass} from "@angular/common";
import {OrderTypes} from "../../utils/OrderTypeConstants";

const ORDER_DETAIL_WIDTH: string = '90%';

@Component({
  selector: 'app-order',
  standalone: true,
  imports: [
    MatIcon,
    MatTooltip,
    CdkDrag,
    NgClass
  ],
  template: `
    <div class="d-flex justify-content-center " cdkDrag (cdkDragStarted)="startedDragging()">
      <div (click)="openOrder()" id="orderSummary"
           style="margin-bottom: 20px; display: flex; justify-content: space-between"
           class="clickable bg-light border border-secondary border-1 rounded-2 col-11">
          <span style="padding: 5px;">
            <div>Order #{{ order.orderId }}</div>
            <div>{{ order.companyName }}</div>
            <div [ngClass]="{'activation-type-icon-new': order.orderType === OrderTypes.NEW,
              'activation-type-icon-existing': order.orderType === OrderTypes.EXISTING,
              'activation-type-icon-deactivation': order.orderType === OrderTypes.DEACTIVATION,
              'activation-type-icon-swap': order.orderType === OrderTypes.SWAP}"
                 matTooltip="{{order.orderType}}">{{ order.orderType?.at(0) }}
            </div>
          </span>
        <span>
          <mat-icon aria-hidden="false" aria-label="Delete order" class="clickable"
                    matTooltip="Delete Order"
                    (click)="deleteOrder(); $event.stopPropagation()">delete
          </mat-icon>
        </span>
      </div>
    </div>
  `,
  styleUrl: './order-summary.component.css'
})
export class OrderSummaryComponent {
  @Input() groupOrders!: any;
  @Input() order!: Order;
  orderService = inject(OrderService);

  isDragging: boolean = false;

  constructor(public dialog: MatDialog) {
  }

  openOrder() {
    if (!this.isDragging) {
      // refreshing order in case any changes have been made
      this.orderService.findById(this.order.orderId).subscribe(order => {
        const dialogRef = this.dialog.open(OrderDetailComponent, {
          width: ORDER_DETAIL_WIDTH
        });
        dialogRef.componentInstance.setOrder(order);

        dialogRef.afterClosed().subscribe(() => {
          clearInterval(dialogRef.componentInstance.activationForm?.refreshInterval);
          this.orderService.findById(this.order.orderId).subscribe((order) => {
            this.order = order;
          });
        });
      })
    } else {
      this.isDragging = false;
    }
  }

  deleteOrder() {
    if (!this.isDragging) {
      const dialogRef = this.dialog.open(DeleteOrderComponent);

      dialogRef.componentInstance.setOrder(this.order);

      dialogRef.afterClosed().subscribe(() => {
        this.groupOrders.callGetOrdersMethod();
      });
    } else {
      this.isDragging = false;
    }
  }

  startedDragging() {
    this.isDragging = true;
  }

  protected readonly OrderTypes = OrderTypes;
}
